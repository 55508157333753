
import { defineComponent } from "vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import HeaderSearchBackground from "@/shared/components/HeaderSearchBackground.vue";

export default defineComponent({
  props: {
    heading: String,
    subheading: String,
    description: String,
  },
  setup() {},
  components: {
    PageLayout,
    HeaderSearchBackground,
  },
});
