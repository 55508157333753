import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "top-performers-page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageLayoutCardHeader = _resolveComponent("PageLayoutCardHeader")!
  const _component_LoginBackground = _resolveComponent("LoginBackground")!
  const _component_PerformerSearchForm = _resolveComponent("PerformerSearchForm")!
  const _component_PerformerGrid = _resolveComponent("PerformerGrid")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageLayoutCardHeader, {
      heading: _ctx.t(`Get killer video creatives and see your brand grow.`),
      subheading: 
        _ctx.t(
          `Be it organic posting or ads, your strategy is nothing if you have no killer video content.`
        )
      ,
      description: 
        _ctx.t(
          `We are your dream content partner. No need to spend hours of coordinating with model creators only to be ignored or ghosted. No more paying thousands of pesos. You'll get the same if not higher quality from {merchantBrand}. Work with us and our model creators and see your ads revenue, engagement and sales soar!`,
          { merchantBrand: _ctx.config.merchantBrand }
        )
      ,
      narrow: ""
    }, null, 8, ["heading", "subheading", "description"]),
    _createVNode(_component_PageLayout, {
      title: _ctx.t('Check the available model creators in our community'),
      subtitle: 
        _ctx.t(
          'These creators are well-versed in creating attention grabbing and scroll-stopping videos. Each creator has their own area of expertise and style. Check them and their previous work to see who fits your brand like a glove. '
        )
      ,
      class: "top-performers-layout"
    }, {
      complexBackground: _withCtx(() => [
        _createVNode(_component_LoginBackground, { class: "top-performers-page__background" })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_PerformerSearchForm, {
          filters: _ctx.performersFilter,
          loading: _ctx.publicPerformersLoading || _ctx.publicPerformersFetchMoreLoading,
          onOnEnterButton: _ctx.handleSearch,
          onOnSearch: _ctx.handleSearch,
          "search-on-keystroke": "",
          class: "top-performers-page__search-form mb-10",
          gutter: [32, 8]
        }, null, 8, ["filters", "loading", "onOnEnterButton", "onOnSearch"]),
        _createVNode(_component_PerformerGrid, {
          "performers-list": _ctx.publicPerformers ?? [],
          loading: _ctx.publicPerformersLoading,
          "load-more-loading": _ctx.publicPerformersFetchMoreLoading,
          "has-next-page": _ctx.publicPerformersHasNextPage,
          onOnLoadMore: _ctx.publicPerformersFetchMore,
          onOnItemClick: _ctx.handlePerformerClick,
          class: "mb-40"
        }, null, 8, ["performers-list", "loading", "load-more-loading", "has-next-page", "onOnLoadMore", "onOnItemClick"])
      ]),
      _: 1
    }, 8, ["title", "subtitle"])
  ]))
}