
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import PageLayoutCardHeader from "@/shared/components/Layouts/PageLayoutCardHeader.vue";
import PerformerGrid from "@/shared/components/Performers/PerformerGrid.vue";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { usePublicPerformers } from "@/shared/composables/usePublicPerformers";
import routeNames from "@/web/router/routeNames";
import LoginBackground from "@/web/views/Login/LoginBackground.vue";
import { config } from "@/shared/utils/config";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import PerformerSearchForm from "@/shared/components/Performers/PerformerSearchForm.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();
    const { redirectToPerformerDetails } = useRedirectHelper();

    const {
      performersFilter,
      publicPerformers,
      publicPerformersLoading,
      publicPerformersFetchMore,
      publicPerformersFetchMoreLoading,
      publicPerformersHasNextPage,
    } = usePublicPerformers();

    const handlePerformerClick = (id: string) => {
      const performerDetails = publicPerformers.value.find(
        (performer) => performer.id === id
      );

      if (performerDetails) {
        redirectToPerformerDetails({ id: performerDetails.id, public: true });
      }
    };

    const handleSeeAllClick = () => {
      router.push({
        name: routeNames.home,
      });
    };

    const handleSearch = (query: string) => {
      performersFilter.query = query;
    };

    return {
      t,
      config,

      performersFilter,
      publicPerformers,
      publicPerformersLoading,
      publicPerformersFetchMore,
      publicPerformersFetchMoreLoading,
      publicPerformersHasNextPage,

      handleSearch,
      handlePerformerClick,
      handleSeeAllClick,
    };
  },
  components: {
    PageLayout,
    PageLayoutCardHeader,
    PerformerGrid,
    LoginBackground,
    PerformerSearchForm,
  },
});
