import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, mergeProps as _mergeProps } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeaderSearchBackground = _resolveComponent("HeaderSearchBackground")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, _mergeProps(_ctx.$attrs, { class: "page-layout-card-header text-center relative" }), {
    complexBackground: _withCtx(() => [
      _createVNode(_component_HeaderSearchBackground)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_card, { class: "p-2 mx-0 my-6 md:p-8 md:my-14 border-0 shadow-md" }, {
        default: _withCtx(() => [
          (_ctx.heading)
            ? (_openBlock(), _createBlock(_component_a_typography_title, {
                key: 0,
                level: 2,
                class: "page-layout-card-header__title mb-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.heading), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.subheading)
            ? (_openBlock(), _createBlock(_component_a_typography_title, {
                key: 1,
                level: 5,
                class: "mb-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.subheading), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (_ctx.description)
            ? (_openBlock(), _createBlock(_component_a_typography_text, {
                key: 2,
                class: "mb-4"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.description), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 16))
}