import { usePerformers } from "@/shared/composables/usePerformers";
import { computed } from "vue";

export const PUBLIC_PERFORMER_PAGE_SIZE = 20;

export const usePublicPerformers = () => {
  const {
    performersFilter,
    performers: publicPerformers,
    performersLoading: publicPerformersLoading,
    performersFetchMore: publicPerformersFetchMore,
    performersFetchMoreLoading: publicPerformersFetchMoreLoading,
    performersHasNextPage: publicPerformersHasNextPage,
  } = usePerformers(PUBLIC_PERFORMER_PAGE_SIZE, {
    isPublic: true,
  });

  return {
    performersFilter,
    // Return response, with isLocked set to false
    publicPerformers: computed(() =>
      publicPerformers.value.map((performer) => ({
        ...performer,
        performerProfile: {
          ...performer.performerProfile,
          isLocked: false,
        },
      }))
    ),
    publicPerformersLoading,
    publicPerformersFetchMore,
    publicPerformersFetchMoreLoading,
    publicPerformersHasNextPage,
  };
};
